import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import {Navbar, Nav, FormControl, Dropdown, Badge, ListGroup, Media, Form, Button} from 'react-bootstrap'
import layoutHelpers from './helpers'
import axios from "axios";
import helpers from "./helpers";
import AuthService from "../../services/auth-service";
import {withTranslation} from "react-i18next";
import Sidenav from "../../vendor/libs/sidenav";
import SidenavRouterLink from "../../vendor/libs/sidenav/SidenavRouterLink";
import i18n from "../../i18n";

class LayoutNavbar extends Component {
  constructor(props) {
    super(props);
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl';
    this.fullName = helpers.getUser() ? helpers.getUser().fullName : "";

    this.state = {search: this.props.match.params.query ? this.props.match.params.query : ""};
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed()
  }

  logout(e) {
    helpers.getAPI().then(api => {
      axios.get(api + '/nsp/account/logout')
          .then(res => {
            AuthService.logout();
          }).catch(err => {
            AuthService.logout();
          })
    })
  }

  myProfile() {
    this.props.history.push("/users/view/"+helpers.getUser().id)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    this.props.history.push('/search/'+this.state.search)
  }

  render() {
    return (
      <Navbar style={{background: '#00394e'}} expand="lg" className="layout-navbar align-items-lg-center container-p-x">
        {/* Brand demo (see src/demo.css) */}
        <Navbar.Brand as={NavLink} to="/" className="app-brand demo py-0 mr-4 py-3">
            <span className="app-brand-logo demo">
                <img style={{width: '145px'}} src={helpers.getLogo()}/>
            </span>
        </Navbar.Brand>

        {/* Sidenav toggle (see src/demo.css) */}
        {this.props.sidenavToggle && (
            <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" >
              <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu text-large align-middle"></i>
              </Nav.Item>
            </Nav>
        )}

        <Navbar.Toggle />

        <Navbar.Collapse>
          {/* Divider */}
          <hr className="d-lg-none w-100 my-2" />

          <Nav className="align-items-lg-center" >

          </Nav>

          <Nav className="align-items-lg-center ml-auto">
            <Form.Group className={"mt-3 mr-1"}>
              <Form.Control as={"select"} value={this.props.i18n.language} onChange={(e) => this.props.i18n.changeLanguage(e.target.value)}>
                <option value='da'>Danish</option>
                <option value='no'>Norwegian</option>
                <option value='se'>Swedish</option>
                <option value='en'>English</option>
                <option value='fi'>Finnish</option>
              </Form.Control>
            </Form.Group>
            {/* Divider */}
            {!(!helpers.getUser() && window.location.pathname == "/create/repair") && <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>}

            {(!helpers.getUser() && window.location.pathname != "/create/repair") && <Link to={"/login"}  className={"btn btn-primary px-5"}>{this.props.t('Sign in')}</Link>}

            {helpers.getUser() && <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
              <Dropdown.Toggle as={Nav.Link} className={"text-white"}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  <img src={`${process.env.PUBLIC_URL}/img/avatars/1.png`} className="d-block ui-w-30 rounded-circle" alt="User" />
                  <span className="px-1 mr-lg-2 ml-2 ml-lg-0 text-white">{this.fullName}</span>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {helpers.getUser() && <Dropdown.Item as={NavLink} to={"/account"}><i className="ion ion-ios-person"></i> &nbsp; {this.props.t("My account")}</Dropdown.Item>}

                  <Dropdown.Item onClick={this.logout}><i className="ion ion-ios-log-out text-danger"></i> &nbsp; {this.props.t("Log out")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

export default withTranslation()(connect(store => ({
  navbarBg: store.theme.navbarBg
}))(LayoutNavbar));
